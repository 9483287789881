import Api from '@/api/Api'

export default {
  get() {
    return Api().get(`/testimony`)
  },

  create(data) {
    return Api().post(`/testimony`, data)
  },

  update(id, data) {
    return Api().put(`/testimony?_id=${id}`, data)
  },

  delete(id) {
    return Api().delete(`/testimony?_id=${id}`)
  },

  getField() {
    return Api().get(`/testimony/add`)
  },

  getDetail(id) {
    return Api().get(`/testimony/detail?_id=${id}`)
  },
 

}