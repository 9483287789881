import Api from '@/api/Api'

export default {
  getWithQuery(type) {
    return Api().get(`/formnewsletter?type=${type}`)
  },
  
  get() {
    return Api().get(`/formnewsletter`)
  },

  create(data) {
    return Api().post(`/formnewsletter`, data)
  },

  update(id, data) {
    return Api().put(`/formnewsletter?_id=${id}`, data)
  },

  delete(id) {
    return Api().delete(`/formnewsletter?_id=${id}`)
  },

  getField() {
    return Api().get(`/formnewsletter/add`)
  },

  getDetail(id) {
    return Api().get(`/formnewsletter/detail?_id=${id}`)
  },
 

}
