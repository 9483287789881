import Menu from '@/api/MenuApi'
  import Category from '@/api/CategoryApi'
  import Availability from '@/api/AvailabilityApi'
  import Location from '@/api/LocationApi'
  import Department from '@/api/DepartmentApi'
  import Product from '@/api/ProductApi'
  import Transform from '@/api/TransformApi'
  import Banner from '@/api/BannerApi'
  import Testimony from '@/api/TestimonyApi'
  import Core from '@/api/CoreApi'
  import Formnewsletter from '@/api/FormnewsletterApi'
  import Training from '@/api/TrainingApi'
  import Job from '@/api/JobApi'
  import Brand from '@/api/BrandApi'
  import About from '@/api/AboutApi'
  import Career from '@/api/CareerApi'
  import Contact from '@/api/ContactApi'
  import CaseStudy from '@/api/CaseStudyApi'
  import Experience from '@/api/ExperienceApi'
  import BrandPage from '@/api/BrandPageApi'
  import Home from '@/api/HomeApi'
  import Phone from '@/api/PhoneApi'
  import Footer from '@/api/FooterApi'
  import Header from '@/api/HeaderApi'
  import Footerlist from '@/api/FooterlistApi'
  import NewsEventDetail from '@/api/NewsEventDetailApi'
  import NewsEvent from '@/api/NewsEventApi'
  import ServiceSolution from '@/api/ServiceSolutionApi'
  import Store from '@/api/StoreApi'
  import Field from '@/api/FieldApi'
  import User from '@/api/UserApi'
  import Fileupload from '@/api/FileUploadApi'
  import global from '@/global.js'
  

export default {
  data() {
    return {
      editorData: '<p>Content of the editor.</p>',
      editorConfig: {
          // The configuration of the editor.
        extraPlugins: "uploadimage",
        filebrowserUploadUrl: `${global.url_server}/file/uploadEditor?model=${this.$route.path.split(`/`)[1]}`,
      }
    }
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string ? string.charAt(0).toUpperCase() + string.slice(1) : string;
    },

    getAuth : async function (path) {
      let Model = path == `/category` ? Category :
        path == `/availability` ? Availability : 
        path == `/location` ? Location :
        path == `/banner` ? Banner :
        path == `/department` ? Department :
        path == `/product` ? Product :
        path == `/core` ? Core :
        path == `/testimony` ? Testimony :
        path == `/transform` ? Transform :
        path == `/menu` ? Menu :
        path == `/phone` ? Phone :
        path == `/footer` ? Footer :
        path == `/header` ? Header :
        path == `/footer-list` ? Footerlist :
        path == `/formnewsletter` ? Formnewsletter :
        path == `/subscribe` ? Formnewsletter :
        path == `/training` ? Training :
        path == `/field` ? Field :
        path == `/job` ? Job :
        path == `/brand-list` ? Brand :
        path == `/about` ? About :
        path == `/career` ? Career :
        path == `/contact` ? Contact :
        path == `/case-study` ? CaseStudy :
        path == `/experience` ? Experience :
        path == `/brand` ? BrandPage :
        path == `/home` ? Home :
        path == `/news-event-detail` ? NewsEventDetail :
        path == `/news-event` ? NewsEvent :
        path == `/service-solution` ? ServiceSolution :
        path == `/store` ? Store :
        path == `/file-upload` ? Fileupload :
        path == `/user` ? User : null;
      return Model;
    },

    getItemBreadCrumbs: async function (name) {
      let arr = [];
      arr.push({
        text: (name == `ProductDetail`) ? 'Product' : 
          (name == `CategoryDetail`) ? `Category` : 
          (name == `DepartmentDetail`) ? `Department` : 
          (name == `LocationDetail`) ? `Location` : 
          (name == `AvailabilityDetail`) ? `Availability` : 
          (name == `BannerDetail`) ? `Banner` : 
          (name == `JobDetail`) ? `Job` : 
          (name == `PhoneDetail`) ? `Phone` : 
          (name == `FooterDetail`) ? `Footer` : 
          (name == `HeaderDetail`) ? `Header` : 
          (name == `FooterlistDetail`) ? `Footerlist` : 
          (name == `CoreDetail`) ? `Core` : 
          (name == `TransformDetail`) ? `Transform` : 
          (name == `TestimonyDetail`) ? `Testimony` : 
          (name == `BrandDetail`) ? `Brand` : 
          (name == `TrainingDetail`) ? `Training` : 
          (name == `FieldDetail`) ? `Field` : 
          (name == `LogisticDetail`) ? `Logistic` : 
          (name == `FormnewsletterDetail`) ? `Form and Letter` : 
          (name == `MenuDetail`) ? `Menu` : 
          (name == `UserDetail`) ? `User` : 
          (name == `AboutDetail`) ? `About` : 
          (name == `BrandPageDetail`) ? `Brand Page` : 
          (name == `CareerDetail`) ? `Career` : 
          (name == `ContactDetail`) ? `Contact` : 
          (name == `CaseStudyDetail`) ? `Case Study` : 
          (name == `ExperienceDetail`) ? `Experience` : 
          (name == `HomeDetail`) ? `Home` : 
          (name == `NewsEventContentDetail`) ? `News and Content Detail` : 
          (name == `NewsEventDetail`) ? `News and Event` : 
          (name == `ServiceSolutionDetail`) ? `Service Solution List` : 
          (name == `StoreDetail`) ? `Store` : null,
        disabled: false,
        href: (name == `ProductDetail`) ? '/product' : 
          (name == `CategoryDetail`) ? `/category` : 
          (name == `DepartmentDetail`) ? `/department` : 
          (name == `LocationDetail`) ? `/location` : 
          (name == `AvailabilityDetail`) ? `/availability` : 
          (name == `JobDetail`) ? `/job` : 
          (name == `TestimonyDetail`) ? `/testimony` : 
          (name == `TransformDetail`) ? `/transform` : 
          (name == `CoreDetail`) ? `/core` : 
          (name == `PhoneDetail`) ? `/phone` : 
          (name == `FooterDetail`) ? `/footer` : 
          (name == `HeaderDetail`) ? `/header` : 
          (name == `FooterlistDetail`) ? `/footer-list` : 
          (name == `BrandDetail`) ? `/brand-list` : 
          (name == `BannerDetail`) ? `/banner` : 
          (name == `TrainingDetail`) ? `/training` : 
          (name == `FieldDetail`) ? `/field` : 
          (name == `LogisticDetail`) ? `/logistic` : 
          (name == `FormnewsletterDetail`) ? `/formnewsletter` : 
          (name == `MenuDetail`) ? `/menu` : 
          (name == `UserDetail`) ? `/user` : 
          (name == `AboutDetail`) ? `/about` : 
          (name == `BrandPageDetail`) ? `/brand` : 
          (name == `CareerDetail`) ? `/career` : 
          (name == `ContactDetail`) ? `/contact` : 
          (name == `CaseStudyDetail`) ? `/case-study` : 
          (name == `ExperienceDetail`) ? `/experience` : 
          (name == `HomeDetail`) ? `/home` : 
          (name == `NewsEventContentDetail`) ? `/news-event-detail` : 
          (name == `NewsEventDetail`) ? `/news-event` : 
          (name == `ServiceSolutionDetail`) ? `/service-solution` : 
          (name == `StoreDetail`) ? `/store` : null
      })
      return arr;
    },

    getMenuInfoMixin : async function (path) {
      let obj = { status: null, data: {}, msg : null };
      let filter = {route: path, is_delete: false}
      let res = await Menu.getMenuInfo(filter);
      if (res.data.status == 200) {
        obj = {
          status: 200,
          msg: `Success`,
          data : {
            button_setting : res.data.data.button_setting ? res.data.data.button_setting : null,
            button_hover : res.data.data.button_hover ? res.data.data.button_hover : null,
            button_name : res.data.data.button_name ? res.data.data.button_name : null,
            menu_name : res.data.data.menu_name,
            menu_info : res.data.data.menu_info
          },
        }
      } else {
        obj = {
          status: 400,
          msg: res.data.message,
          data : null,
        }
      }
      return obj;
    },
  }
}
