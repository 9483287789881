<template>
  <v-navigation-drawer class="navigation-drawer-style" permanent>
    <v-list-item>
      <v-list-item-content class="mt-4">
        <v-list-item-title>
          <v-img
            max-width="100%"
            :src=logo
            @error="logo=`${url_server}/files/logo_file/default_logo.svg`"
          />
        </v-list-item-title>
        <v-list-item-subtitle class="pt-2 text-center">
          Hello &nbsp;<b>{{ user.email }}</b> 
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider class="line-divide"></v-divider>

    <v-list shaped dense>
      <v-list-item-group v-model="selected_item" color="black">
        <div v-for="item in items" :key="item.title">
          <!-- NO SUB MENU -->
          <v-list-item 
            v-if="item.sub_menu.length == 0" 
            shaped 
            dense 
            :href="item.route"
            :disabled=disabled_list
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content >
              <v-list-item-title>
                  {{ item.menu_name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          
          <!-- SUB MENU -->
          <v-list-group 
            v-else 
            :prepend-icon="item.icon" 
            v-model="item.active" 
            color="black"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{item.menu_name}}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="(sub, i) in item.sub_menu"
              :key="i"
              :href="sub.route"
            >
              <v-list-item-title 
                class="ml-2" 
                v-text="sub.name" 
              />
            </v-list-item>
          </v-list-group>
        </div>
      </v-list-item-group>
    </v-list>

    <template v-slot:append>
      <div class="pa-5">
        <v-btn outlined block @click="logout" class="btn-border">
          Logout
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
  import Auth from '@/api/AuthApi'
  import Menu from '@/api/MenuApi'
  import global from '@/global.js'

  export default {
    data () {
      return {
        url_server: global.url_server,        
        selected_item: 0,
        items: [],
        disabled_list: false,
        user: null,
        right: null,
        logo: `${global.url_server}/files/logo_file/default_logo.svg`
      }
    },
    created : async function () {
      this.user = JSON.parse(this.$cookie.get(`data_user`));
      let promise = this.getSideBar()
      Promise.all([promise]).then(() => {
        this.setActiveBar();
       
      });
    },
    methods : {
      setActiveBar() {
        setTimeout(() => {
          let temppath = this.$route.fullPath.substring(1,this.$route.fullPath.length);
          let path = temppath.substring(0, temppath.indexOf("/")) ? temppath.substring(0, temppath.indexOf("/")) : temppath;
          let now_path = this.items.indexOf(this.items.find(dt => dt.route == `/${path}`));
          if (now_path == -1) {
            this.items.map((item, index) => {
              if (item.sub_menu.find(ism => ism.route == `/${path}`)) {
                item.active = true
                now_path = index;
              } else {
                item.active = false
              }
            });
          }
          this.selected_item = now_path
          if (this.selected_item == -1) {
            this.$router.push({ name: `PageNotFound` });
          }
        }, 500);
      },

      /* reload(item) {
        this.setActiveBar();
        if (item.route != this.$route.path) {
          this.$router.push({ path: item.route })
        }
      }, */
      getSideBar: async function() {
        let res = await Menu.getSideBar();
        if (res.data.status == 200) {
          if (res.data.data.length > 0) {
            let new_data = res.data.data;
            let show_menu = this.user.menu;
            let arr_parent = [];
            
            //push dashboard to avoid error
            arr_parent.push(new_data[0]);

            // find submenu first to get the parent
            for (let i = 0; i < new_data.length; i++) {
              let temp = new_data[i]; 
              if (temp.sub_menu.length > 0) {
                let arr_child = [], parent_id = null;
                for (let j = 0; j < temp.sub_menu.length; j++) {
                  let temp_sub = temp.sub_menu[j];
                  if (show_menu.filter(smf => smf == temp_sub._id).length > 0) {
                    parent_id = temp._id
                    arr_child.push(temp_sub);
                  } 
                }

                // to add new child and put in in new parent array
                if (parent_id) {
                  new_data.find(ndf => ndf._id == parent_id).sub_menu = arr_child;
                  arr_parent.push(new_data.find(ndf => ndf._id == parent_id));
                }
              } else {
                // add parent array
                if (show_menu.filter(smf => smf == temp._id).length > 0) {
                  arr_parent.push(temp);
                }
              }
            }
            
            this.items = arr_parent;
          }
        } else {
          console.log(`Error`)
          console.log(res.data.message)
        }
      },

      logout: async function() {
        let res = await Auth.logout({ _id: this.user._id});
        if (res.data.status == 200) {
          this.$cookie.delete("token");
          this.$cookie.delete("data_user");
          
          location.reload();
          setTimeout(() => {
            this.$router.push({ path: `/login` });
          }, 600);
        } else {
          console.log(`Error when logout`);
          console.log(res.data);
        }

      }
    }
  }
</script>

<style scoped>
  .navigation-drawer-style {
    border-right: 3px solid !important;
  }
  .line-divide {
    border-top: none;
    border-bottom: 2px solid !important;
  }
  .v-application--is-ltr .v-list.v-sheet--shaped .v-list-item, 
  .v-application--is-ltr .v-list.v-sheet--shaped .v-list-item::before, 
  .v-application--is-ltr .v-list.v-sheet--shaped .v-list-item > .v-ripple__container {
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-top-left-radius: 32px !important;
    border-bottom-left-radius: 32px !important;
  }
  .v-application--is-ltr .v-list.v-sheet--shaped {
    padding-left: 8px !important;
    padding-right: 0 !important;
  }
  .v-list-item-group .v-list-item--active {
    color: white !important;
  }
  .v-list-item--active {
    background-color: black;
  }
</style>

