export default {
  //LOCAL
  // url_server: 'http://localhost:1011'

  // PRODUCTION
  url_server: 'https://api.orionnusantara.com'

  // TESTING
  // url_server: 'http://34.101.231.179/indobudget2021'
}
