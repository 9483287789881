<template>
  <v-app v-bind:style="$route.path == `/login` ? { background: `black` } : { background: `#EEEEEE` }">
    
    <v-main v-if="token">
      <v-row class="null" style="height: 100%; margin: 0;">
        <v-col class="null" cols=2>
          <SideBar />
        </v-col>

        <v-col cols=10>
          <router-view> </router-view>
        </v-col>
      </v-row>
    </v-main>

    <v-main v-else>
      <router-view> </router-view>
    </v-main>
    
  </v-app>
</template>

<script>
import SideBar from '@/components/SideBar.vue'

export default {
  name: 'App',
  components: {
    SideBar
  },

  data() {
    return {
      token: null
    }
  },

  created () {
    this.token = this.$cookie.get("token");
    if (!this.$cookie.get("token") && this.$route.path != `/login`) {
      this.$router.push({path : `/login`})
    }
  }
};
</script>

<style>
@font-face {
  font-family: "Inter-Light";
  src: url('./assets/fonts/Inter-Light/Inter-Light.ttf') format('truetype');
}

#app {
  font-family: 'Inter-Light', 'Avenir', Helvetica, Arial, sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
}


/* html {
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
    width: 0px; 
} */
/* BASE STANDART */
.btn-border {
  border-radius: 0;
}
.null {
  margin: 0;
  padding: 0 !important;
}
.bs {
  border: 0.2vmin solid grey;
  border-radius: 1vmin;
}

.cb {
  display: flex;
}
.cb.all {
  justify-content: center;
  align-items: center;
}
.cb.vertical {
  align-items: center;
}
.cb.horizontal {
  justify-content: center;
}
.cb.between {
  justify-content: space-between;
}
.cb.around {
  justify-content: space-around;
}
.cb.row {
  flex-direction: row;
}
.cb.top {
  align-items: flex-start;
}
.cb.bottom {
  align-items: flex-end;
}
.cb.left {
  justify-content: flex-start;
}
.cb.right {
  justify-content: flex-end;
}
body::-webkit-scrollbar {
  width: 12px;               /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: white;        /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: black;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid white;  /* creates padding around scroll thumb */
}
</style>
