import Api from '@/api/Api'

export default {
  get() {
    return Api().get(`/availability`)
  },

  create(data) {
    return Api().post(`/availability`, data)
  },

  update(id, data) {
    return Api().put(`/availability?_id=${id}`, data)
  },

  delete(id) {
    return Api().delete(`/availability?_id=${id}`)
  },

  getField() {
    return Api().get(`/availability/add`)
  },

  getDetail(id) {
    return Api().get(`/availability/detail?_id=${id}`)
  },
 

}