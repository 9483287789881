import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import VueJwtDecode from 'vue-jwt-decode'
import VueCookie from 'vue-cookie'
import CKEditor from 'ckeditor4-vue';


// Require dependencies

Vue.config.productionTip = false

Vue.use(CKEditor);
Vue.use(VueCookie);
Vue.use(VueJwtDecode)

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
