import Api from '@/api/Api'

export default {
  get() {
    return Api().get(`/category`)
  },

  create(data) {
    return Api().post(`/category`, data)
  },

  update(id, data) {
    return Api().put(`/category?_id=${id}`, data)
  },

  delete(id) {
    return Api().delete(`/category?_id=${id}`)
  },

  getField() {
    return Api().get(`/category/add`)
  },

  getDetail(id) {
    return Api().get(`/category/detail?_id=${id}`)
  },
 

}