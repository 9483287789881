import Api from '@/api/Api'

export default {
  get() {
    return Api().get(`/phone`)
  },

  create(data) {
    return Api().post(`/phone`, data)
  },

  update(id, data) {
    return Api().put(`/phone?_id=${id}`, data)
  },

  delete(id) {
    return Api().delete(`/phone?_id=${id}`)
  },

  getField() {
    return Api().get(`/phone/add`)
  },

  getDetail(id) {
    return Api().get(`/phone/detail?_id=${id}`)
  },
 

}