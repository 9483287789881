import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/product',
    name: 'Product',
    component: () => import('../views/Product/Product.vue')
  },
  {
    path: '/product/:id',
    name: 'ProductDetail',
    component: () => import('../views/Product/ProductDetail.vue')
  },
  {
    path: '/category',
    name: 'Category',
    component: () => import('../views/Category/Category.vue')
  },
  {
    path: '/category/:id',
    name: 'CategoryDetail',
    component: () => import('../views/Category/CategoryDetail.vue')
  },
  {
    path: '/department',
    name: 'Department',
    component: () => import('../views/Department/Department.vue')
  },
  {
    path: '/department/:id',
    name: 'DepartmentDetail',
    component: () => import('../views/Department/DepartmentDetail.vue')
  },
  {
    path: '/location',
    name: 'Location',
    component: () => import('../views/Location/Location.vue')
  },
  {
    path: '/location/:id',
    name: 'LocationDetail',
    component: () => import('../views/Location/LocationDetail.vue')
  },
  {
    path: '/availability',
    name: 'Availability',
    component: () => import('../views/Availability/Availability.vue')
  },
  {
    path: '/availability/:id',
    name: 'AvailabilityDetail',
    component: () => import('../views/Availability/AvailabilityDetail.vue')
  },
  {
    path: '/job',
    name: 'Job',
    component: () => import('../views/Job/Job.vue')
  },
  {
    path: '/job/:id',
    name: 'JobDetail',
    component: () => import('../views/Job/JobDetail.vue')
  },
  {
    path: '/brand-list',
    name: 'Brand',
    component: () => import('../views/Brand/Brand.vue')
  },
  {
    path: '/brand-list/:id',
    name: 'BrandDetail',
    component: () => import('../views/Brand/BrandDetail.vue')
  },
  {
    path: '/training',
    name: 'Training',
    component: () => import('../views/Training/Training.vue')
  },
  {
    path: '/training/:id',
    name: 'TrainingDetail',
    component: () => import('../views/Training/TrainingDetail.vue')
  },
  {
    path: '/logistic',
    name: 'Logistic',
    component: () => import('../views/Logistic/Logistic.vue')
  },
  {
    path: '/logistic/:id',
    name: 'LogisticDetail',
    component: () => import('../views/Logistic/LogisticDetail.vue')
  },
  {
    path: '/formnewsletter',
    name: 'Formnewsletter',
    component: () => import('../views/Formnewsletter/Formnewsletter.vue')
  },
  {
    path: '/formnewsletter/:id',
    name: 'FormnewsletterDetail',
    component: () => import('../views/Formnewsletter/FormnewsletterDetail.vue')
  },
  {
    path: '/subscribe',
    name: 'Subscribe',
    component: () => import('../views/Subscribe/Subscribe.vue')
  },
  {
    path: '/subscribe/:id',
    name: 'SubscribeDetail',
    component: () => import('../views/Subscribe/SubscribeDetail.vue')
  },
  {
    path: '/field',
    name: 'Field',
    component: () => import('../views/Field/Field.vue')
  },
  {
    path: '/field/:id',
    name: 'FieldDetail',
    component: () => import('../views/Field/FieldDetail.vue')
  },
  {
    path: '/menu',
    name: 'Menu',
    component: () => import('../views/Menu/Menu.vue')
  },
  {
    path: '/menu/:id',
    name: 'MenuDetail',
    component: () => import('../views/Menu/MenuDetail.vue')
  },
  {
    path: '/user',
    name: 'User',
    component: () => import('../views/User/User.vue')
  },
  {
    path: '/user/:id',
    name: 'UserDetail',
    component: () => import('../views/User/UserDetail.vue')
  },
  {
    path: '/transform',
    name: 'Transform',
    component: () => import('../views/Transform/Transform.vue')
  },
  {
    path: '/transform/:id',
    name: 'TransformDetail',
    component: () => import('../views/Transform/TransformDetail.vue')
  },
  {
    path: '/testimony',
    name: 'Testimony',
    component: () => import('../views/Testimony/Testimony.vue')
  },
  {
    path: '/testimony/:id',
    name: 'TestimonyDetail',
    component: () => import('../views/Testimony/TestimonyDetail.vue')
  },
  {
    path: '/banner',
    name: 'Banner',
    component: () => import('../views/Banner/Banner.vue')
  },
  {
    path: '/banner/:id',
    name: 'BannerDetail',
    component: () => import('../views/Banner/BannerDetail.vue')
  },
  {
    path: '/core',
    name: 'Core',
    component: () => import('../views/Core/Core.vue')
  },
  {
    path: '/core/:id',
    name: 'CoreDetail',
    component: () => import('../views/Core/CoreDetail.vue')
  },
  {
    path: '/phone',
    name: 'Phone',
    component: () => import('../views/Phone/Phone.vue')
  },
  {
    path: '/phone/:id',
    name: 'PhoneDetail',
    component: () => import('../views/Phone/PhoneDetail.vue')
  },
  {
    path: '/footer-list',
    name: 'Footerlist',
    component: () => import('../views/Footerlist/Footerlist.vue')
  },
  {
    path: '/footer-list/:id',
    name: 'FooterlistDetail',
    component: () => import('../views/Footerlist/FooterlistDetail.vue')
  },
  {
    path: '/footer',
    name: 'Footer',
    component: () => import('../views/Footer/Footer.vue')
  },
  {
    path: '/footer/:id',
    name: 'FooterDetail',
    component: () => import('../views/Footer/FooterDetail.vue')
  },
  {
    path: '/header',
    name: 'Header',
    component: () => import('../views/Header/Header.vue')
  },
  {
    path: '/header/:id',
    name: 'HeaderDetail',
    component: () => import('../views/Header/HeaderDetail.vue')
  },
  /* =================== */


  /* PAGE */
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/Page/About/About.vue')
  },
  {
    path: '/about/:id',
    name: 'AboutDetail',
    component: () => import('../views/Page/About/AboutDetail.vue')
  },
  {
    path: '/brand',
    name: 'BrandPage',
    component: () => import('../views/Page/Brand/Brand.vue')
  },
  {
    path: '/brand/:id',
    name: 'BrandPageDetail',
    component: () => import('../views/Page/Brand/BrandDetail.vue')
  },
  {
    path: '/career',
    name: 'Career',
    component: () => import('../views/Page/Career/Career.vue')
  },
  {
    path: '/career/:id',
    name: 'CareerDetail',
    component: () => import('../views/Page/Career/CareerDetail.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Page/Contact/Contact.vue')
  },
  {
    path: '/contact/:id',
    name: 'ContactDetail',
    component: () => import('../views/Page/Contact/ContactDetail.vue')
  },
  {
    path: '/case-study',
    name: 'CaseStudy',
    component: () => import('../views/Page/CaseStudy/CaseStudy.vue')
  },
  {
    path: '/case-study/:id',
    name: 'CaseStudyDetail',
    component: () => import('../views/Page/CaseStudy/CaseStudyDetail.vue')
  },
  {
    path: '/experience',
    name: 'Experience',
    component: () => import('../views/Page/Experience/Experience.vue')
  },
  {
    path: '/experience/:id',
    name: 'ExperienceDetail',
    component: () => import('../views/Page/Experience/ExperienceDetail.vue')
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Page/Home/Home.vue')
  },
  {
    path: '/home/:id',
    name: 'HomeDetail',
    component: () => import('../views/Page/Home/HomeDetail.vue')
  },
  {
    path: '/news-event-detail',
    name: 'NewsEventContent',
    component: () => import('../views/Page/NewsEventContent/NewsEventContent.vue')
  },
  {
    path: '/news-event-detail/:id',
    name: 'NewsEventContentDetail',
    component: () => import('../views/Page/NewsEventContent/NewsEventContentDetail.vue')
  },
  {
    path: '/news-event',
    name: 'NewsEvent',
    component: () => import('../views/Page/NewsEvent/NewsEvent.vue')
  },
  {
    path: '/news-event/:id',
    name: 'NewsEventDetail',
    component: () => import('../views/Page/NewsEvent/NewsEventDetail.vue')
  },
  {
    path: '/service-solution',
    name: 'ServiceSolution',
    component: () => import('../views/Page/ServiceSolution/ServiceSolution.vue')
  },
  {
    path: '/service-solution/:id',
    name: 'ServiceSolutionDetail',
    component: () => import('../views/Page/ServiceSolution/ServiceSolutionDetail.vue')
  },
  {
    path: '/store',
    name: 'Store',
    component: () => import('../views/Page/Store/Store.vue')
  },
  {
    path: '/store/:id',
    name: 'StoreDetail',
    component: () => import('../views/Page/Store/StoreDetail.vue')
  },
  
  /* =================== */

  {
    path: '*',
    name: 'PageNotFound',
    component: () => import('../views/NotFound.vue')
  }
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
