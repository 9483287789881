<template>
  <div class="home">
    <v-row v-if="loading">
      <v-col cols="6" md="3" sm="3" v-for="ct in 8" :key="ct">
        <v-card class="mx-auto" max-width="344">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="mb-1">
                <v-skeleton-loader
                  type="card-heading"
                ></v-skeleton-loader>
              </v-list-item-title>
            </v-list-item-content>

            <h1>
              <v-skeleton-loader
                type="avatar"
              ></v-skeleton-loader>
            </h1>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col cols="6" md="3" sm="3" v-for="ct in contents" :key="ct.name">
        <v-card class="mx-auto" max-width="344">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="mb-1">
                {{ct.name}}
              </v-list-item-title>
            </v-list-item-content>

            <h1>
              {{ct.data_length}}
            </h1>
          </v-list-item>

          <!-- <v-card-actions>
            <v-btn
              rounded
              text
            >
              Button
            </v-btn>
          </v-card-actions> -->
        </v-card>
      </v-col>
    </v-row>

   <!--  <v-row>
      <v-col cols="12" md="6" sm="6" v-for="tb in table" :key="tb.table_name">
        <v-data-table
          :headers="tb.fields"
          :items="tb.content"
          :items-per-page="5"
          class="elevation-1"
        ></v-data-table>
      </v-col>
    </v-row> -->
  </div>
</template>
<script>
import Menu from '@/api/MenuApi'
import Mixins from '@/mixins/mixin'

export default {
  name: 'Dashboard',
  mixins: [Mixins],

  data () {
    return {
      contents: [],
      table: [],
      loading: true
    }
  },
  /* mounted () {
    let user = JSON.parse(this.$cookie.get(`data_user`));
    if (this.$cookie.get(`token`) && !user.is_super_admin) {
      this.$router.push({ name: 'Dashboard' });
    }
  }, */
  created () {
    let promise = this.getHomeContent()
    Promise.all([promise]).then(() => {
      // console.log(`Done`);
    });
    
  },
  methods: {
    getHomeContent: async function() {
      let res = await Menu.getHomeContent();
      if (res.data.status == 200) {
        res.data.data.data.map(rdd => {
          this.contents.push({
            name: this.capitalizeFirstLetter(rdd.name),
            data_length: rdd.data_length
          })
        });
        /* for (const property in res.data.data) {
          if (!property.includes(`table`)) {
            this.contents.push({
              name: capitalizeFirstLetter(property.name),
              data_length: res.data.data[property]
            })
          }
        } */
        // this.table = res.data.data.table
        this.loading = false;
        // let superadmin = res.data.data.filter(word => word.is_super_admin == true);
        // let vendor = res.data.data.filter(word => word.is_admin == true);
      } else {
        console.log(`Error`)
        console.log(res.data.message)
      }
    },
  }
}
</script>

<style scoped>
  .home {
    padding: 20px 30px;
  }
</style>
