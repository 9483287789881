import Api from '@/api/Api'

export default {
  /* BACKOFFICE */
  getMenuInfo(data) {
    return Api().post(`/menu/get-menu-info`, data)
  },
  
  getSideBar() {
    return Api().get('/menu/side-bar')
  },

  getHomeContent() {
    return Api().get('/menu/get-home-content')
  },

  get() {
    return Api().get(`/menu`)
  },

  create(data) {
    return Api().post(`/menu`, data)
  },

  update(id, data) {
    return Api().put(`/menu?_id=${id}`, data)
  },

  delete(id) {
    return Api().delete(`/menu?_id=${id}`)
  },

  getField() {
    return Api().get(`/menu/add`)
  },

  getDetail(id) {
    return Api().get(`/menu/detail?_id=${id}`)
  },
  /* BACKOFFICE */


  /* getMenuContent() {
    return Api().get('/menu/get_menu_content')
  },

  get(headers, params) {
    let content = {
      headers,
      params
    }
    return Api().get(`/menu`, content)
  },
 */
  /* getField() {
    return Api().get(`/menu/get_field`)
  },

  CreateData(data, headers) {
    return Api().post(`/menu/`, data, headers)
  },
  
  UpdateData(data, headers, _id) {
    return Api().post(`/menu/update_data?_id=${_id}`, data, headers)
  },

  deleteItem(headers, data) {
    return Api().delete(`/menu/delete_item?_id=${data}`, { headers })
  },

  CreateChildData(data) {
    return Api().post(`/menu/create_child_data`, data)
  },

  DeleteChildData(data) {
    return Api().post(`/menu/delete_child_data`, data)
  }, */
}
